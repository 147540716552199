import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./About.css";

function About() {
  return (
    <>
      <div className="mt-[80px] ">

      </div>
    </>
  );
}

export default About;
