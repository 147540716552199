import React, { useState,useRef,useEffect } from "react";
import ContactForm from "./ContactForm";

function ContactUsPage() {
  

  
  
  return (
    <>
      <section className="pt-[85px] bg-white dark:bg-[#292929] ">
        <ContactForm/>
      </section>
    </>
  );
}

export default ContactUsPage;
